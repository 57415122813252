import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { UserAccountSelect } from '~/pages/strategy_center_admin/_components/UserAccountSelect';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { InstrumentsFilter } from '~/pages/strategy_center_admin/strategy-overview/_components/InstrumentsFilter';
import { TagsFilter } from '~/pages/strategy_center_admin/strategy-overview/_components/TagsFilter';
import { StrategyOverviewEditForm } from './StrategyOverviewEditForm';
import { apirc } from '~/configs/apirc';
export const StrategyOverviewFilter = () => {
    const filter = pageStore.strategyOverviewStore.filter;
    const form = useForm({
        initialValues: {
            strategistAccount: filter.strategistAccount,
            strategyName: filter.strategyName,
        },
    });
    return (<div css={css `
        width: 100%;
        height: 100%;
      `}>
      <form onSubmit={form.onSubmit(values => {
            filter.strategistAccount = values.strategistAccount;
            filter.strategyName = values.strategyName;
        })} css={css `
          display: grid;
          grid-template-rows: 64px 48px 48px;
        `}>
        <div css={css `
            ${fill_horizontal_cross_center};
            align-items: end;
            gap: 4px;
            padding: 2px 0;
            border-bottom: 1px solid #cccccc;
            box-sizing: border-box;
          `}>
          <classes.strategyNameSearch>
            <TextInput label='策略名稱' {...form.getInputProps('strategyName')} css={css `
                width: 100%;
              `}/>
          </classes.strategyNameSearch>

          <classes.strategyAuthor>
            <UserAccountSelect label='策略師' {...form.getInputProps('strategistAccount')} onChange={selected => {
            form.setFieldValue('strategistAccount', selected || '');
        }} css={css `
                width: 100%;
              `}/>
          </classes.strategyAuthor>
          <Button type='submit' onClick={event => {
            filter.strategistAccount = form.getInputProps('strategistAccount').value;
            filter.strategyName = form.getInputProps('strategyName').value;
        }}>
            查詢
          </Button>
          <Button onClick={event => {
            filter.strategistAccount = '';
            filter.strategyName = '';
            filter.instruments = [];
            filter.tags = [];
            form.setValues({
                strategistAccount: '',
                strategyName: '',
            });
        }}>
            清除
          </Button>
          <Button color='green' onClick={event => {
            pageStore.modalStore.showModalComponent(() => {
                return (<StrategyOverviewEditForm initialValues={{}} onCancel={pageStore.modalStore.closeModal} onCreate={createDto => {
                        apirc.strategy
                            .http('post', '/api/strategies')
                            .request({ ...createDto })
                            .then(pageStore.strategyOverviewStore.loadData)
                            .then(pageStore.modalStore.closeModal);
                    }}/>);
            });
        }}>
            <div>新增策略</div>
          </Button>
        </div>
        <div css={css `
            ${fill_horizontal_cross_center};
            align-items: end;
            gap: 4px;
            padding: 2px 0;
            border-bottom: 1px solid #cccccc;
            box-sizing: border-box;
          `}>
          <classes.strategySymbolSelecter>
            <InstrumentsFilter onChange={instruments => {
            filter.instruments = instruments;
        }}/>
          </classes.strategySymbolSelecter>
        </div>
        <div css={css `
            ${fill_horizontal_cross_center};
            align-items: end;
            padding: 2px 0;
            border-bottom: 1px solid #cccccc;
            box-sizing: border-box;
          `}>
          <classes.strategyTags>
            <TagsFilter onChange={tags => {
            filter.tags = tags;
        }}/>
          </classes.strategyTags>
        </div>
      </form>
    </div>);
};
const classes = {
    strategyNameSearch: styled.div `
    ${fill_horizontal_all_center};
    width: 200px;
  `,
    strategySymbolSelecter: styled.div `
    ${fill_horizontal_all_center};
    width: auto;
    gap: 4px;
  `,
    strategyAuthor: styled.div `
    ${fill_horizontal_all_center};
    width: 200px;
  `,
    strategyTags: styled.div `
    ${fill_horizontal_all_center};
    width: 200px;
  `,
};
